@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Gilroy";
    src: local("Gilroy"), url(./fonts/Gilroy-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: "Gilroy";
    src: local("Gilroy-Medium"),
      url(./fonts/Gilroy-Medium.ttf) format("truetype");
    font-style: medium;
    font-weight: 500;
  }
  @font-face {
    font-family: "Gilroy";
    src: local("Gilroy-SemiBold"),
      url(./fonts/Gilroy-SemiBold.ttf) format("truetype");
    font-style: semibold;
    font-weight: 600;
  }
  @font-face {
    font-family: "Gilroy";
    src: local("Gilroy-Bold"), url(./fonts/Gilroy-Bold.ttf) format("truetype");
    font-style: bold;
    font-weight: 700;
  }
  @font-face {
    font-family: "Eina02";
    src: local("Eina02"), url(./fonts/Eina02-Regular.ttf) format("truetype");
    font-style: normal;
    font-weight: 400;
  }
  @font-face {
    font-family: "Eina02";
    src: local("Eina02-SemiBold"),
      url(./fonts/Eina02-SemiBold.ttf) format("truetype");
    font-style: semibold;
    font-weight: 600;
  }
  @font-face {
    font-family: "Eina02";
    src: local("Eina02-Bold"), url(./fonts/Eina02-Bold.ttf) format("truetype");
    font-style: bold;
    font-weight: 700;
  }
  @font-face {
    font-family: "Causten";
    src: local("Causten-SemiBold"),
      url(./fonts/Causten-SemiBold.ttf) format("truetype");
    font-style: semibold;
    font-weight: 600;
  }
}

@layer components {
  .btn {
    @apply flex justify-center items-center font-gilroy font-semibold text-gray-600 px-8 py-2 cursor-pointer rounded shadow hover:shadow-inner;
  }
  
  .btn-gray {
    @apply shadow-none hover:shadow-none text-gray-400 hover:text-gray-600 hover:underline p-0;
  }
  
  .btn-white {
    @apply bg-white text-blue border border-blue;
  }

  .btn-blue {
    @apply bg-blue text-white border border-blue;
  }

  .btn-purple {
    @apply bg-purple text-white border border-purple;
  }
  
  .btn-red {
    @apply bg-red text-white border border-red;
  }
  
  .btn-green {
    @apply bg-green text-white border border-green;
  }
  
  .btn-yellow {
    @apply bg-yellow text-white border border-yellow;
  }
  .btn:disabled {
    @apply bg-gray-400 text-white border border-gray-400 cursor-not-allowed;
  }

  .btn-google {
    background-image: url("https://static.portant.co/btn-google-signin.png");
    background-size: cover;
    width: 191px;
    height: 46px;
  }
  
  .btn-google.light {
    background-image: url("https://static.portant.co/btn-google-signin-light.png");
  }
  
  .btn-google:hover {
    background-image: url("https://static.portant.co/btn-google-signin-pressed.png");
  }
  
  .btn-google.light:hover {
    background-image: url("https://static.portant.co/btn-google-signin-light-pressed.png");
  }
  
  .btn-google::after {  /* preload */
    content: url("https://static.portant.co/btn-google-signin-pressed.png");
    position: absolute; width: 0; height: 0; overflow: hidden; z-index: -9999;
  }
  
  .btn-google.light::after {  /* preload */
    content: url("https://static.portant.co/btn-google-signin-light-pressed.png");
    position: absolute; width: 0; height: 0; overflow: hidden; z-index: -9999;
  }

  .link {
    @apply text-blue underline;
  }

  .tooltip {
    @apply absolute px-2 py-1 z-10 bg-black text-white text-xs whitespace-nowrap rounded-md hidden group-hover:block;
  }
  
  .loading-ellipse::after {
    display: inline-block;
    animation: loading-ellips-animation steps(1, end) 1s infinite;
    content: '';
  }

  .shake {
    animation: shake-animation steps(1, end) 100ms infinite;
  }
  
  @keyframes loading-ellips-animation {
      0%   { content: ''; }
      25%  { content: '.'; }
      50%  { content: '..'; }
      75%  { content: '...'; }
      100% { content: ''; }
  }
  
  @keyframes shake-animation {
    0% { transform: translateX(0) }
    25% { transform: translateX(2px) }
    50% { transform: translateX(-2px) }
    75% { transform: translateX(2px) }
    100% { transform: translateX(0) }
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .scrollbar-hidden::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .scrollbar-hidden {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }    
    
    .scrollbar-visible::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
    }

    .scrollbar-visible::-webkit-scrollbar-thumb {
      width: 7px;
      background-color: rgba(0, 0, 0, .1);
      border-radius: 4px;
    }

    .scrollbar-gutter {
      padding-right: 8px;
    }
  }
}
